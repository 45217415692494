export const openingInventoryDefaultColumns = [
	"inventory_product_id",
	"inventory_product",

	"total",
	"unit_id",
	"unit",

	"custom_unit_value",
	"custom_unit",
];
export const openingInventoryColumns = [...openingInventoryDefaultColumns];

export const openingInventoryDetailedDefaultColumns = [
	"batch_id",
	"batch_name",
	"location_id",
	"location_name",
	"initial_cultivar_id",
	"initial_cultivar_name",
	"current_cultivar_id",
	"current_cultivar_name",
	"inventory_product_id",
	"inventory_product_name",
	"inventory_id",
	"inventory_name",
	"inventory_age",
	"total",
	"unit_id",
	"unit",
	"lot_id",
	"lot_name",
	"batch_status",
	"batch_archived",
	"sku_id",
	"sku",
	"vendor_id",
	"vendor",
	"inventory_type",
	"total_in_display_unit",
	"display_unit_id",
	"display_unit",
	"inventory_status",
	"custom_unit_value",
	"custom_unit",
];
export const openingInventoryDetailedColumns = [
	...openingInventoryDetailedDefaultColumns,
	"lot_description",
];

export const plantsOpeningInventoryDefaultColumns = [
	"growth_stage_name",
	"total",
];
export const plantsOpeningInventoryColumns = [
	...plantsOpeningInventoryDefaultColumns,
];

export const plantsOpeningInventoryDetailedDefaultColumns = [
	"plant_id",
	"plant_name",
	"batch_id",
	"batch_name",
	"cultivar_id",
	"cultivar_name",
	"location_id",
	"location_name",
	"total",
	"growth_stage_id",
	"growth_stage_name",
	"time_start_growth_stage",
	"days_in_growth_stage",
	"batch_status",
	"batch_type",
	"batch_archived",
];
export const plantsOpeningInventoryDetailedColumns = [
	...plantsOpeningInventoryDetailedDefaultColumns,
];

export const allOpeningInventoryColumns = [
	...new Set([
		...openingInventoryColumns,
		...openingInventoryDetailedColumns,
		...plantsOpeningInventoryColumns,
		...plantsOpeningInventoryDetailedColumns,
	]),
];

export const additionsReductionsDefaultColumns = [
	"measure_event_id",
	"measure_event_type",
	"performed_on",
	"recorded_on",
	"batch_id",
	"batch_name",
	"cultivar_id",
	"cultivar_name",
	"inventory_product_id",
	"inventory_product_name",
	"lot_id",
	"lot_name",
	"inventory_id",
	"inventory_name",
	"total",
	"unit_id",
	"unit",
	"work_order_id",
	"work_order_name",
	"work_order_type_id",
	"work_order_type",
	"work_order_opened_at",
	"work_order_closed_at",
	"user_id",
	"user_email",
	"sku_id",
	"sku",
	"vendor_id",
	"vendor",
	"total_in_display_unit",
	"display_unit_id",
	"display_unit",
	"custom_unit_value",
	"custom_unit",
];
export const additionsReductionsColumns = [
	...additionsReductionsDefaultColumns,
	"lot_description",
];

export const plantsAdditionsReductionsDefaultColumns = [
	"performed_on",
	"recorded_on",
	"cts_field",
	"batch_id",
	"batch_name",
	"cultivar_id",
	"cultivar_name",
	"growth_stage_id",
	"growth_stage_name",
	"work_order_id",
	"work_order",
	"work_order_type_id",
	"work_order_type",
	"work_order_opened_at",
	"work_order_closed_at",
	"total",
	"user_id",
	"user_email",
];
export const plantsAdditionsReductionsColumns = [
	...plantsAdditionsReductionsDefaultColumns,
];

export const allAdditionsReductionsColumns = [
	...new Set([
		...plantsAdditionsReductionsColumns,
		...additionsReductionsColumns,
	]),
];

export const reportingCategoryOpeningInventoryDefaultColumns = [
	"inventory_product_id",
	"inventory_product",

	"total",
	"unit_id",
	"unit",

	"custom_unit",
	"custom_unit_value",
];
export const reportingCategoryOpeningInventoryColumns = [
	...reportingCategoryOpeningInventoryDefaultColumns,
];

export const reportingCategoryOpeningInventoryDetailedDefaultColumns = [
	"batch_id",
	"batch_name",

	"location_id",
	"location_name",

	"initial_cultivar_id",
	"initial_cultivar_name",
	"current_cultivar_id",
	"current_cultivar_name",

	"inventory_product_id",
	"inventory_product_name",

	"inventory_id",
	"inventory_name",
	"inventory_age",

	"total",
	"unit_id",
	"unit",

	"lot_id",
	"lot_name",

	"batch_status",
	"batch_archived",

	"sku_id",
	"sku",

	"vendor_id",
	"vendor",

	"inventory_type",

	"total_in_display_unit",
	"display_unit_id",
	"display_unit",

	"inventory_status",

	"custom_unit_value",
	"custom_unit",

	"work_order_id",
	"work_order_name",

	"shipping_order_id",
	"shipping_order_name",
];
export const reportingCategoryOpeningInventoryDetailedColumns = [
	...reportingCategoryOpeningInventoryDetailedDefaultColumns,
	"lot_description",
	"lot_status_id",
	"lot_status_name",
];

export const allReportingCategoryOpeningInventoryColumns = [
	...new Set([
		...reportingCategoryOpeningInventoryColumns,
		...reportingCategoryOpeningInventoryDetailedColumns,
	]),
];

export const reportingCategoryAdditionsReductionsDefaultColumns = [
	"measure_event_id",
	"measure_event_type",
	"performed_on",
	"recorded_on",

	"batch_id",
	"batch_name",

	"cultivar_id",
	"cultivar_name",

	"inventory_product_id",
	"inventory_product_name",

	"lot_id",
	"lot_name",
	"inventory_id",
	"inventory_name",

	"total",
	"unit_id",
	"unit",

	"work_order_id",
	"work_order_name",
	"work_order_type_id",
	"work_order_type",

	"work_order_location_id",
	"work_order_location_name",
	"work_order_locked",

	"work_order_opened_at",
	"work_order_closed_at",

	"user_id",
	"user_email",

	"sku_id",
	"sku",

	"vendor_id",
	"vendor",

	"total_in_display_unit",
	"display_unit_id",
	"display_unit",

	"custom_unit_value",
	"custom_unit",
];
export const reportingCategoryAdditionsReductionsColumns = [
	...reportingCategoryAdditionsReductionsDefaultColumns,
	"lot_description",
];

export const reportingCategoryDestructionsDefaultColumns = [
	"destruction_event_id",
	"performed_on",
	"recorded_on",

	"destruction_lot_id",
	"destruction_lot_name",

	"destruction_reason_id",
	"destruction_reason",

	"batch_id",
	"batch_name",

	"inventory_product_id",
	"inventory_product_name",

	"total",
	"unit_id",
	"unit",

	"inventory_id",
	"inventory_name",

	"lot_id",
	"lot_name",

	"work_order_id",
	"work_order_name",
	"work_order_type_id",
	"work_order_type_name",
	"work_order_opened_at",
	"work_order_closed_at",

	"user_email",
	"user_id",

	"destroyed_on",

	"sku_id",
	"sku",

	"vendor_id",
	"vendor",

	"total_in_display_unit",
	"display_unit_id",
	"display_unit",

	"custom_unit_value",
	"custom_unit",
];
export const reportingCategoryDestructionsColumns = [
	...reportingCategoryDestructionsDefaultColumns,
	"lot_description",
];

export const allReportingCategoryAdditionsReductionsColumns = [
	...new Set([
		...reportingCategoryAdditionsReductionsColumns,
		...reportingCategoryDestructionsColumns,
	]),
];

export const destructionsDefaultColumns = [
	"destruction_event_id",
	"performed_on",
	"recorded_on",
	"destruction_lot_id",
	"destruction_lot_name",
	"destruction_reason_id",
	"destruction_reason",
	"batch_id",
	"batch_name",
	"inventory_product_id",
	"inventory_product_name",
	"total",
	"unit_id",
	"unit",
	"inventory_id",
	"inventory_name",
	"lot_id",
	"lot_name",
	"work_order_id",
	"work_order_name",
	"work_order_type_id",
	"work_order_type_name",
	"work_order_opened_at",
	"work_order_closed_at",
	"user_email",
	"user_id",
	"destroyed_on",
	"sku_id",
	"sku",
	"vendor_id",
	"vendor",
	"total_in_display_unit",
	"display_unit_id",
	"display_unit",
	"custom_unit_value",
	"custom_unit",
];
export const destructionsColumns = [
	...destructionsDefaultColumns,
	"lot_description",
];

export const plantsDestructionsDefaultColumns = [
	"performed_on",
	"recorded_on",
	"cts_field",
	"batch_id",
	"batch_name",
	"cultivar_id",
	"cultivar_name",
	"growth_stage_id",
	"growth_stage_name",
	"total_plants",
	"work_order_id",
	"work_order",
	"work_order_type_id",
	"work_order_type",
	"work_order_opened_at",
	"work_order_closed_at",
	"destruction_reason_id",
	"destruction_reason",
	"total_weight",
	"unit",
	"destruction_lot_id",
	"destruction_lot_name",
	"destroyed_on",
	"user_id",
	"user_email",
];
export const plantsDestructionsColumns = [...plantsDestructionsDefaultColumns];

export const allDestructionsColumns = [
	...new Set([...destructionsColumns, ...plantsDestructionsColumns]),
];

/* eslint-disable @typescript-eslint/naming-convention */
export const reportColumns = {
	"seeds/opening-inventory": {
		defaultColumns: openingInventoryDefaultColumns,
		columns: openingInventoryColumns,
	},
	"seeds/opening-inventory/detailed": {
		defaultColumns: openingInventoryDetailedDefaultColumns,
		columns: openingInventoryDetailedColumns,
	},
	"seeds/additions-reductions": {
		defaultColumns: additionsReductionsDefaultColumns,
		columns: additionsReductionsColumns,
	},
	"seeds/destructions": {
		defaultColumns: destructionsDefaultColumns,
		columns: destructionsColumns,
	},

	"cultivations/opening-inventory": {
		defaultColumns: openingInventoryDefaultColumns,
		columns: openingInventoryColumns,
	},
	"cultivations/opening-inventory/detailed": {
		defaultColumns: openingInventoryDetailedDefaultColumns,
		columns: openingInventoryDetailedColumns,
	},
	"cultivations/additions-reductions": {
		defaultColumns: additionsReductionsDefaultColumns,
		columns: additionsReductionsColumns,
	},
	"cultivations/destructions": {
		defaultColumns: destructionsDefaultColumns,
		columns: destructionsColumns,
	},

	"plants/opening-inventory": {
		defaultColumns: plantsOpeningInventoryDefaultColumns,
		columns: plantsOpeningInventoryColumns,
	},
	"plants/opening-inventory/detailed": {
		defaultColumns: plantsOpeningInventoryDetailedDefaultColumns,
		columns: plantsOpeningInventoryDetailedColumns,
	},
	"plants/additions-reductions": {
		defaultColumns: plantsAdditionsReductionsDefaultColumns,
		columns: plantsAdditionsReductionsColumns,
	},
	"plants/destructions": {
		defaultColumns: plantsDestructionsDefaultColumns,
		columns: plantsDestructionsColumns,
	},

	"reporting-category/opening-inventory": {
		defaultColumns: reportingCategoryOpeningInventoryDefaultColumns,
		columns: reportingCategoryOpeningInventoryColumns,
	},
	"reporting-category/opening-inventory/detailed": {
		defaultColumns: reportingCategoryOpeningInventoryDetailedDefaultColumns,
		columns: reportingCategoryOpeningInventoryDetailedColumns,
	},
	"reporting-category/additions-reductions": {
		defaultColumns: reportingCategoryAdditionsReductionsDefaultColumns,
		columns: reportingCategoryAdditionsReductionsColumns,
	},
	"reporting-category/destructions": {
		defaultColumns: reportingCategoryDestructionsDefaultColumns,
		columns: reportingCategoryDestructionsColumns,
	},
};
/* eslint-enable @typescript-eslint/naming-convention */
